// import * as firebase from 'firebase';
// import "firebase/auth";

import { isNull } from 'lodash';
import api from '../../api/authentication.js';
import router from '../../routes/index.js';

const namespaced = true

const loginUrl   = '/login';
const defaultUrl = '/reports/cards-history';

const state = {
    iaccept: false,
    isLoggedIn: false, 
    userAuth: {
        name: '',
        email: '',
        photoUrl: null, 
        emailVerified: false,
        uid: ''
    },
    showLogoutDialog : false,
    providerParameters : {
        prompt : 'select_account'
    }
}

const mutations = {
    MUTATION_SET_USER(state, value) {
        state.userAuth = value;
    },
    MUTATION_SET_LOGOUT(state, value) {
        state.showLogoutDialog = value;
    },
    SET_LOGINSTATUS(state, input_data){
        state.isLoggedIn = input_data;
    },
}

const actions = {
    login_server(context, data) {
        return new Promise((resolve) => { 
            api.login_server(data).then(resp => {
                context.dispatch('notifications/add', { 'color': isNull(resp.data.token) ? 'error' : 'success', 'visibility': true, 'timeout': 5000, 'text': resp.data.message }, { root: true });
                context.dispatch('configuration/setUserAuth',resp.data.user, {root: true });
                context.dispatch('redirectAfterLogin', resp.data);
                resolve(resp);
            }).catch(error => {
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error.response.data.data.message}, {root: true });
            })
        })        
   },
   redirectAfterLogin(context, data){
        if(!isNull(data.token)){
            router.push(defaultUrl)
        }
   },
   redirectAfterLogout(context, data){
        context.dispatch('configuration/setUserAuth',{ id: 0},{root: true });
        if(!isNull(data.code) || !data.code){
            router.push(loginUrl);
        }
   },
   checkToken(context){
        api.checkToken().then(resp => {
            if(resp.data.code){
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': resp.data.message }, { root: true });
                if(router.currentRoute.name == 'login'){
                    router.push(defaultUrl);
                }
            }else{
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
                if(router.currentRoute.name != 'login'){
                    router.push(loginUrl);
                }
            }
        }).catch(error => {
            console.log(error);
            if(router.currentRoute.name != 'login'){
                router.push(loginUrl);
            }
        });
   },
   logout(context){
        api.logout().then(resp => {
            context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
            context.dispatch('redirectAfterLogout', resp.data);
        }).catch(() => {
            context.dispatch('redirectAfterLogout', { 'code': false });
        });
   },

}

const getters = {

}


export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}