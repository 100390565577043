const namespaced = true

import _ from 'lodash';
import api from '../../api/trello.js';

const state = {
    loading: false,
    projects: [],
    projectSelected: { id: 0 },
    boards: [],
    boardSelected: { id: 0 },
    selected: '',
    onhold: [],
    develop: [],
    blocked: [],
    finished: [],
    cards: [],
    onboarding: 0,
    users: [],
    userSelected: { id: 0 },
    snapshotStatus: 'Tomar Snapshot',
}
const mutations = {
    MUTATION_SET_LOADING(state, value) {
        state.loading = value;
    },
    MUTATION_SET_PROJECT(state, value) {
        state.projects = value;
    },
    MUTATION_SET_BOARD(state, value) {
        state.boards = value;
    },
    MUTATION_SET_USER(state, value){
        state.users = value;
    },
    MUTATION_SET_CARD_SELECTED(state, value){
        state.selected = value;
    },
    MUTATION_SET_CARD_ONBOARDING(state, value){
        state.onboarding = value;
    },
    MUTATION_SET_BOARD_SELECTED(state, value){
        state.boardSelected = value;
    },
    MUTATION_SET_PROJECT_SELECTED(state, value){
        state.projectSelected = value;
    },
    MUTATION_SET_USER_SELECTED(state, value){
        state.userSelected = value;
    },
    MUTATION_SET_SNAPSHOT_STATUS(state, value){
        state.snapshotStatus = value;
    }
}
const actions = {     
    getProjects(context) {
        context.commit('MUTATION_SET_LOADING', true);
        return new Promise((resolve, reject) => { 
            api.getProjects().then(function (res) {
                context.commit('MUTATION_SET_PROJECT', res.data);
                context.commit('MUTATION_SET_LOADING', false);
                resolve(true);
            }).catch(function (error) {
                console.log(error);
                context.commit('MUTATION_SET_LOADING', false);
                reject(false);
            })
        })        
    },
    getBoards(context, project){
        context.commit('MUTATION_SET_LOADING', true);
        context.commit('MUTATION_SET_BOARD', []);
        return new Promise((resolve, reject)=>{
            api.getBoards(project).then(resp => {
                context.commit('MUTATION_SET_PROJECT_SELECTED', project);
                context.commit('MUTATION_SET_BOARD_SELECTED', { id: 0});
                context.commit('MUTATION_SET_BOARD', resp.data);
                context.commit('MUTATION_SET_LOADING', false);
                resolve(true);
            }).catch(error => {
                console.log(error);
                context.commit('MUTATION_SET_LOADING', false);
                reject(false);
            });
        });
    },
    getBoardsHistory(context, {project, date1, date2}){
        context.commit('MUTATION_SET_LOADING', true);
        context.commit('MUTATION_SET_BOARD', []);
        return new Promise((resolve, reject)=>{
            api.getBoardsHistory(project.go_to, date1, date2).then(resp => {
                context.commit('MUTATION_SET_PROJECT_SELECTED', project);
                context.commit('MUTATION_SET_BOARD_SELECTED', { id: 0});
                context.commit('MUTATION_SET_BOARD', resp.data);
                context.commit('MUTATION_SET_LOADING', false);
                resolve(true);
            }).catch(error => {
                console.log(error);
                context.commit('MUTATION_SET_LOADING', false);
                reject(false);
            });
        });
    },
    getUsers(context) {
        context.commit('MUTATION_SET_LOADING', true);
        return new Promise((resolve, reject) => { 
            api.getUsers().then(function (res) {
                context.commit('MUTATION_SET_USER', res.data);
                context.commit('MUTATION_SET_LOADING', false);
                resolve(true);
            }).catch(function (error) {
                console.log(error);
                context.commit('MUTATION_SET_LOADING', false);
                reject(false);
            })
        })        
    },
    clear(context){
        context.commit('MUTATION_SET_CARD_ONBOARDING', 0);
        context.commit('MUTATION_SET_CARD_SELECTED', '');
        context.commit('MUTATION_SET_BOARD_SELECTED', { id: 0});
        context.commit('MUTATION_SET_USER_SELECTED', { id: 0 });
    },
    prevCard(context){
        context.commit('MUTATION_SET_CARD_ONBOARDING', context.state.onboarding - 1);
    },
    nextCard(context){
        context.commit('MUTATION_SET_CARD_ONBOARDING', context.state.onboarding + 1);
    },
    selectCard(context, card){
        context.commit('MUTATION_SET_CARD_ONBOARDING', 0);
        context.commit('MUTATION_SET_CARD_SELECTED', card);
    },
    selectProject(context, project){
        context.commit('MUTATION_SET_CARD_ONBOARDING', 0);
        context.commit('MUTATION_SET_PROJECT_SELECTED', project);
    },
    selectBoard(context, board){
        context.commit('MUTATION_SET_CARD_ONBOARDING', 0);
        context.commit('MUTATION_SET_BOARD_SELECTED', board);
    },
    selectUser(context, user){
        context.commit('MUTATION_SET_CARD_ONBOARDING', 0);
        context.commit('MUTATION_SET_CARD_SELECTED', '');
        context.commit('MUTATION_SET_USER_SELECTED', user??{ id: 0 });
    },
    takeSnapshot(context){
        return new Promise((resolve, reject) => { 
            context.commit('MUTATION_SET_SNAPSHOT_STATUS', 'Tomando Snapshot...');
            api.takeSnapshot().then(() => {
                setTimeout(()=>{
                    context.commit('MUTATION_SET_SNAPSHOT_STATUS', 'Snapshot Capturado');
                    setTimeout(()=>{
                        context.commit('MUTATION_SET_SNAPSHOT_STATUS', 'Volver a Tomar Snapshot');
                    }, 2000);
                }, 2000);
                resolve(true);
            }).catch((error) => {
                console.log(error);
                context.commit('MUTATION_SET_SNAPSHOT_STATUS', 'ERROR - Tomar Snapshot');
                reject(false);
            })
        });
    },
    deleteCard(context, card){
        return new Promise((resolve, reject) => {
            api.deleteCard(card.id).then(() => {
                let boards = [];
                _.forEach(context.state.boards, board => {
                    let cards = [];
                    _.forEach(board.cards, car => {
                        if(car.id != card.id){
                            cards.push(car);
                        }
                    });
                    board.cards = cards;
                    boards.push(board);
                });
                context.commit('MUTATION_SET_BOARD', []);
                context.commit('MUTATION_SET_BOARD', boards);
                resolve(true);
            }).catch(error => {
                console.log(error);
                reject(false);
            });
        });
    },
}

const getters = {
    onhold(state){
        state.onhold = [];
        _.forEach(state.boards, board => {
            _.forEach( board.cards, card => {
                if(state.boardSelected.id == ((state.boardSelected.id)?card.board_id:0) && ((state.userSelected.id)?_.findIndex(_.union(card.users??[], card.card?card.card.users:[]), user => { return user.id == state.userSelected.id; }):1) != -1 && card.status == 'onhold'){
                    state.onhold.push(card);
                }
            });
        });
        return state.onhold;
    },
    develop(state){
        state.develop = [];
        _.forEach(state.boards, board => {
            _.forEach( board.cards, card => {
                if(state.boardSelected.id == ((state.boardSelected.id)?card.board_id:0) && ((state.userSelected.id)?_.findIndex(_.union(card.users??[], card.card?card.card.users:[]), user => { return user.id == state.userSelected.id; }):1) != -1 && card.status == 'develop'){
                    state.develop.push(card);
                }
            });
        });
        return state.develop;
    },
    blocked(state){
        state.blocked = [];
        _.forEach(state.boards, board => {
            _.forEach( board.cards, card => {
                if(state.boardSelected.id == ((state.boardSelected.id)?card.board_id:0) && ((state.userSelected.id)?_.findIndex(_.union(card.users??[], card.card?card.card.users:[]), user => { return user.id == state.userSelected.id; }):1) != -1 && card.status == 'block'){
                    state.blocked.push(card);
                }
            });
        });
        return state.blocked;
    },
    finished(state){
        state.finished = [];
        _.forEach(state.boards, board => {
            _.forEach( board.cards, card => {
                if(state.boardSelected.id == ((state.boardSelected.id)?card.board_id:0) && ((state.userSelected.id)?_.findIndex(_.union(card.users??[], card.card?card.card.users:[]), user => { return user.id == state.userSelected.id; }):1) != -1 && card.status == 'finished'){
                    state.finished.push(card);
                }
            });
        });
        return state.finished;
    },
    cards(state){
        state.cards = [];
        _.forEach(state.boards, board => {
            _.forEach(board.cards, card => {
                if(state.boardSelected.id == ((state.boardSelected.id)?card.board_id:0) && ((state.userSelected.id)?_.findIndex(card.users, user => { return user.id == state.userSelected.id; }):1) != -1 && card.status == state.selected){
                    state.cards.push(card);
                }
            });
        });
        return state.cards;
    },
    boards(state){
        let boards = [];
        _.forEach(state.boards, board => {
            if(((state.userSelected.id)?_.findIndex(board.users, user => { return user.id == state.userSelected.id; }):1) != -1){
                boards.push(board);
            }
        });
        return boards;
    },
    user(state){
        return state.userSelected;
    },
    onboarding(state){
        return state.onboarding;
    },
}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}