let prefix = '/api/trello/boards';

function store(data){
    return window.axios.post(prefix+'/store', data);
}
function update(id,data){
    return window.axios.patch(prefix+'/'+id+'/update', data);
}
function deleted(id){
    return window.axios.patch(prefix+'/delete'+'/'+id)
}
function getBoards(){
    return window.axios.get('/api/trello/boards/index');
}

export default {
    store,
    update,
    deleted,
    getBoards
}