const namespaced = true

import api from '../../api/board.js';
import _ from 'lodash';

const state = {
   board: [],
   boards: []
}
const mutations = {
    MUTATION_SET_BOARDS(state, value) {
        state.boards = value;
    },
    MUTATION_ADD_BOARD(state, value){
        state.boards.push(value);
    },
    MUTATION_UPDATE_BOARD(state, value){
        let boards = state.boards;
        state.boards = [];
        _.forEach(boards, board => {
            if(board.id != value.id){
                state.boards.push(board);
            }else{
                state.boards.push(value);
            }
        });
    },
    MUTATION_DELETE_USER(state, value){
        let boards = state.boards;
        state.boards = [];
        _.forEach(boards, board => {
            if(board.id != value){
                state.boards.push(board);
            }
        });
    },
}
const actions = {     
    
    update(context, {id, data}){
        return new Promise((resolve, reject) => {
            api.update(id, data).then(resp => {
                context.commit('MUTATION_UPDATE_BOARD', resp.data);
                resolve(resp.data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    store(context, data){
        return new Promise((resolve, reject) => {
            api.store(data).then(resp => {
                context.commit('MUTATION_ADD_BOARD', resp.data);
                // context.dispatch('trello/MUTATION_SET_BOARD',resp.data, { root: true });
                resolve(resp.data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    delete(context, id){
        return new Promise((resolve, reject) => {
            api.deleted(id).then(resp => {
                context.commit('MUTATION_DELETE_USER', id);
                resolve(resp.data);
            }).catch(error => {
                reject(error);
            });
        });
    },
    getBoards(context){
        return new Promise((resolve,reject) => {
            api.getBoards().then(resp => {
                context.commit('MUTATION_SET_BOARDS', resp.data);
                resolve(true);
            }).catch(error => {
                console.log(error);
                reject(false);
            })
        })
    },
}

const getters = {
   
}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}