<template>
    <div>
        <v-app-bar app clipped-left fixed>
            <v-row align="center">
                <v-col cols="2" sm="1" md="1" lg="1" xl="1">
                    <v-btn block outlined @click.stop="drawerConf = !drawerConf"><span class="mdi mdi-menu"></span></v-btn>
                </v-col>
                <v-col cols="10" sm="10" md="10" lg="10" xl="10">
                    <span class="subtitle-1 mx-auto font-weight-bold text-truncate">{{ $route.meta.title }}</span>
                </v-col>               
            </v-row>
        </v-app-bar>

        <v-navigation-drawer v-model="drawerConf" temporary fixed>
            <template v-slot:prepend>
                <v-list-item class="px-4 pt-1">
                    <v-list-item-title>Actividades</v-list-item-title>
                    <v-btn icon @click.stop="drawerConf = !drawerConf">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-list-item>
            </template>
            <v-divider></v-divider>
            <v-list nav dense v-for="(module, index) in modulesOrder" :key="index+'_'+module.title" v-show="can(module.permissions)" v-model="module.active" no-action>
                <v-subheader>{{ module.title }}</v-subheader>
                <v-list-item v-for="(section, index) in module.sections" :key="index+'_'+section.title" link @click="goTo(section.redirect)">
                    <v-list-item-icon>
                        <v-icon>{{ section.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title v-show="can(section.permissions)">{{ section.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-list nav dense v-for="(module, index) in modulesD" :key="index+'_'+module.title" v-show="can(module.permissions)" v-model="module.active" no-action>
                <v-subheader>{{ module.title }}</v-subheader>
                <v-list-item v-for="(section, index) in module.sections" :key="index+'_'+section.title" link @click="goTo(section.redirect, section.params)">
                    <v-list-item-icon>
                        <v-icon>{{ section.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title v-show="can(section.permissions)">{{ section.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list nav dense>
                <v-list-item link>
                    <v-list-item-content>
                        <v-list-item-icon>
                        <v-icon>mdi-brightness-6</v-icon>
                        </v-list-item-icon>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-switch color="accent" v-model="get_is_dark" v-on:change="setDarkMode(get_is_dark)"></v-switch>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
            <template v-slot:append>
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon small color="green">mdi-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="green--text text--lighten-1 text-subtitle-2" :style="{ color: styleErrorColor }">
                            {{ getConfigurationName }}(Sesión)
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="logout">
                    <v-list-item-icon>
                        <v-icon color="error">mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title :style="{ color: styleErrorColor }">
                            Cerrar Sesión
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
         </v-navigation-drawer>
    </div>
</template>

<script>
    import default_profile_picture from '../users/images/default_profile_picture.png';

    import _ from 'lodash';

    import { mapState, mapActions, mapGetters } from 'vuex';
    import { can } from '../../src/helpers/permissions';

    export default {
    data: () => ({
        drawer: true,
        group: null,
        drawerConf: false,
        modules: [
            {
                title: 'Administración',
                active: false,
                permissions: [
                    'get-users', 'store-users', 'get-boards', 'store-boards', 'update-boards', 'delete-boards'
                ],
                sections: [
                    {
                        title: 'Gestión de Usuarios',
                        icon: 'mdi-account-group',
                        redirect: 'users',
                        permissions: [
                            'get-users', 'store-users'
                        ],
                    },{
                        title: 'Tableros',
                        icon: 'mdi-view-dashboard',
                        redirect: 'boards',
                        permissions: [
                            'get-boards', 'store-boards', 'update-boards', 'delete-boards'
                        ],
                    }
                    
                ],
            },
            {
                title: 'Reportes',
                permissions: [
                    'activity-report-projects',
                ],
                sections: [
                    {
                        title: 'Proyectos',
                        icon: 'mdi-chart-bar',
                        redirect: 'reports/cards-history',
                        permissions: [
                            'activity-report-projects'
                        ],
                    }
                ],
            },
        ],
    }),
    components : {
        
    },
    computed: {
        ...mapState('authentication', ['userAuth']),
        ...mapGetters('configuration', ['getAbilitiesUser', 'getConfigurationName']),
        ...mapState('configuration', ['themeDark']),
        ...mapState('trello', ['projects', 'snapshotStatus']),
        modulesOrder(){
            return _.orderBy(this.modules, 'title', 'asc');
        },
        logged() {
            return (this.$route.name !== "home")
        },
        sideMenu() {
            return this.$vuetify.breakpoint.width <= 1264 && this.logged
        },
        get_is_dark: {
            get: function () {
                return this.themeDark;
            },
            set: function (newValue) {
                return newValue;
            }
        },
        styleErrorColor(){
            return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.error : this.$vuetify.theme.themes.light.error;
        },
        modulesD(){
            let modules = [];
            let depart = {
                title: 'Departamentos',
                permissions: [],
                sections: [],
            };
            let sections = [];
            _.forEach(_.orderBy(this.projects, 'description', 'asc'), project => {
                sections.push({
                    title: project.description,
                    icon: project.icon,
                    redirect: 'departments',
                    params: { depart: project.go_to, project: project.id },
                    permissions: [
                        'activity-'+project.go_to
                    ],
                });
                depart.permissions.push('activity-'+project.go_to);
            });
            depart.sections = sections;
            modules.push(depart);
            return modules;
        },
    },
    methods: {
        ...mapActions('trello', ['getProjects']),
        ...mapActions('configuration', ['setDarkMode']),
        can(permissions){
            return can(this.getAbilitiesUser, permissions);
        },
        logout(){
            this.$router.push({ name: "logout" });
        },
        goTo(name, params = null){
            this.$router.push({
                name : name,
                params: params??{},
            }).catch(error => {
                if (error.name != "NavigationDuplicated") {
                    throw error;
                }
            });
        },        
        showhere(route){
            return (this.$route.fullPath != route) ? true : false;
        },
        checkImage(image){
            if(image == 'default_user_image.jpg' || image == 'default_profile_picture.png' || image == null){
                return default_profile_picture;                    
            }else{
                return image;
            }
        }        
    },
    mounted() {
        this.getProjects();
    },
    watch: {
        get_is_dark: function (val) {
            this.$vuetify.theme.dark = val;
        }
    },
    beforeMount(){
        this.$vuetify.theme.dark = this.get_is_dark;
    }
}
</script>

<style>
    .router-link-exact-active {
        background-color: rgba(0, 0, 0, .1);
        border-radius: 5px;
        color: #1867c0 !important;
        caret-color: #1867c0 !important;
        font-style: italic;
        transition: .3s cubic-bezier(.25,.8,.5,1);
    }
</style>