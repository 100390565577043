function getProjects() {
    return window.axios.get('/api/trello/projects');
}
function getBoards(project) {
    return window.axios.get('/api/trello/boards/'+project);
}
function getBoardsHistory(project, date1, date2) {
    return window.axios.get('/api/trello/boards-history?goTo='+project+'&date1='+date1+'&date2='+date2);
}
function getUsers(){
    return window.axios.get('/api/trello/users');
}
function takeSnapshot(){
    return window.axios.post('/api/trello/takeSnapshot');
}
function deleteCard(card_id){
    return window.axios.post('/api/trello/card/'+card_id+'/delete');
}
export default {
    getProjects,
    getBoards,
    getBoardsHistory,
    getUsers,
    takeSnapshot,
    deleteCard,
}