<template>    
    <v-app>
        
        <navigation-menu v-if="currentRoute != 'login'"></navigation-menu>
        <v-main>
            <router-view></router-view>
        </v-main>       

        <v-snackbar bottom right outlined multi-line style="white-space: pre-line" v-model="notification.visibility" :color="notification.color" :timeout="notification.timeout">
            {{ notification.text }}
            <template v-slot:action="{ attrs }">
                <v-btn dark text v-bind="attrs" @click="notification.visibility = false" :color="notification.color">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>        
    </v-app>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import NavigationMenu from './shared/NavigationMenu.vue'

    export default {
        name: 'App',
        components: {
            NavigationMenu
        },
        data: () => ({}),
        methods: {
            ...mapActions('notifications',  ['clear']),
            ...mapActions('authentication', ['checkToken']),
            /**
             * Solo en el caso en que quisieramos ocultarlo en una determinada ruta.
             */
            isShow(){
                return (this.$route.fullPath != '/') ? true : false;
            }
        },
        computed: {
            ...mapState('notifications', ['notification']),
            currentRoute(){
                return this.$route.name
            },
        },
        beforeMount (){
            this.clear();
            this.checkToken();
        }
    }
</script>
