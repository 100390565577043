const namespaced = true

import api from '../../api/project';

const state = {
   project: [],
   projects: []
}
const mutations = {
    MUTATION_SET_PROJECTS(state, value) {
        state.projects = value;
    }
}
const actions = {     
    getProjects(context){
        return new Promise((resolve,reject) => {
            api.getProjects().then(resp => {
                context.commit('MUTATION_SET_PROJECTS', resp.data);
                resolve(true);
            }).catch(error => {
                console.log(error);
                reject(false);
            })
        })
    }
}

const getters = {
   
}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}