import Vue from 'vue';  
import Router from 'vue-router'

Vue.use(Router);

const routerOptions = [
  {
    path: '/',
    name: 'public',
  },
  // {
  //   path: '/support',
  //   name: 'support',
  //   meta: {
  //     title: 'Departamentos / Soporte'
  //   }
  // },
  {
    path: '/login',
    name: 'login',
  },
  {
    path: '/logout',
    name: 'logout',
  },
  {
    path: '/register',
    name: 'register',
  },
  // {
  //   path: '/develop',
  //   name: 'develop',
  //   meta: {
  //     title: 'Departamentos / Desarrollo'
  //   }
  // },
  {
    path: '/departments/:depart/:project',
    name: 'departments',
    meta: {
      title: 'Departamento'
    }
  },
  {
    path: '/reports/cards-history',
    name: 'reports/cards-history',
    meta: {
      title: 'Reportes / Proyectos'
    }
  },
  {
    path: '/users',
    name: 'users',
    meta: {
      title: 'Administración / Gestión de Usuarios',
    },
  },
  {
    path: '/boards',
    name: 'boards',
    meta: {
      title: 'Administración / Tableros',
    },
  }
]

// Rutas
const routes = routerOptions.map(r => {
  return {
    ...r,
    component: () => import(`../components/${r.name}/index-component.vue`)
  }
})

const router = new Router({
  // mode: 'history',
  base: __dirname,
  routes
})

export default router