const namespaced = true

import api     from '../../api/abilities.js';

const state = {
    all_abilities : [],
}
const mutations = {
     MUTATION_SET_ABILITIES(state, value) {
          state.all_abilities = value;
     },
    
}
const actions = {
      // Name Property
     getAbilities(context, all = false) {
          return new Promise((resolve) => { 
               api.getAbilities(all).then(function (res) {
                    context.commit('MUTATION_SET_ABILITIES', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    console.log(error)
               })
          })
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
